.alignment{
    padding-bottom: 0.5%;
}

.tip-bg{
    background-color: #ffcb5a !important;
    color: #071d3c;
}

.answer-pointer {
    cursor: pointer;
}

.answer-color {
    color: #ffcb5a;
}


.answer-padding {
    padding-left: 1.35rem !important;
}


.wrong-answer-color {
    color: red;
}
