.text{
    font-size: 0.8rem;
}

.terminal-cont{
    font-family: "Source Code Pro", monospace;
    width: 700px;
    max-height: 750px;
    background: #0C162D; 
    box-shadow: 0 0 20px #ffcb5a;

}

.border{
    border: 1px solid #FFCB59 !important;
}

.form{
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 480px;
    justify-content: space-evenly;
    align-items: center;
}

.input{
    width: 90px;
    background-color:rgba(0, 0, 0, 0);
    border: none;
    color: white;
}

.input:focus{
    outline: none;
}

.text-padding{
    padding-left: 6%;
    text-indent: -5%;
    padding-bottom: 1%;
}

.form-check-input{
    margin-top: 0.08em;
}
