.cont-1{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #0C162D;  
}

.cont-2{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #071d3c;
}

.Qtip{
    background: #ffcb5a;
    max-height: 20px;  
}

.tip{
    color: #ffcb5a;
    font-weight: bold;
}