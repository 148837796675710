@media only screen and (max-width: 680px) {
   
  #main{
    width: 200px !important;
    height: 200px !important;
    border-radius: 200px !important;
  }

  #screen{
    width: 180px !important;
    height: 180px !important;
    border-radius: 100% !important;
  }

  #home h1 {
    font-size: 50px !important;
  }

  #home #mph {
    font-size: 22px !important;
    bottom: 38px !important;
  }
}

.bg{
  background-color: #071d3c !important;
}

*, *:before, *:after {
    box-sizing: border-box;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
    
  a, h1, h2, h3 {
  
    line-height: 1;
  }
  
  .table {
    display: table;
    width: 100%;
    }
  
  .table-cell {
    display: table-cell;
    vertical-align: middle;
  }
  
  #main {
    width: 280px;
    height: 280px;
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.1s;
    transition: -webkit-transform 0.1s;
    transition: transform 0.1s;
    transition: transform 0.1s, -webkit-transform 0.1s;
    border-radius: 280px;
    background: linear-gradient(165deg, #0066ffa8 0%, #ffcb5a 100%);
    margin: 0 auto;
    box-shadow: 0 0 90px rgb(255, 203, 90);
  }
  
  #screen {
    color: #fff;
    z-index: 100;
    -webkit-font-smoothing: antialiased;
    font-family: "Source Code Pro", monospace;
    background: #010101;
    width: 260px;
    height: 260px;
    margin: 10px;
    border-radius: 100%;
    position: absolute;
    overflow: hidden;
  }
  
  #radial {
    margin: 20px;
    width: 340px;
    height: 340px;
    opacity: 0.5;
  }
  #radial #empty {
    position: absolute;
    width: 90%;
    height: 90%;
    background: linear-gradient(165deg, #153CA8 0%, #ffcb5a 100%);
    border-radius: 100%;
    left: 5%;
    top: 5%;
    pointer-events: none;
  }
  #radial .tick {
    position: absolute;
    width: 1.5px;
    height: 100%;
    left: 50%;
    margin-left: -1px;
    background: white;
    pointer-events: none;
  }
  
  #home {
    position: absolute;
    mix-blend-mode: screen;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  #home h1 {
    position: relative;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -55px;
    line-height: 100px;
    text-align: center;
    font-size: 80px;
    width: 100%;
    letter-spacing: 5px;
  }
  #home #mph {
    opacity: 1;
    position: absolute;
    width: 100%;
    bottom: 50px;
    text-align: center;
    font-size: 32px;
    letter-spacing: 0.25px;
    font-weight: 100;
  }

  .mph{
    margin-left: 0.8rem;
  }

  #home #status, #home h2 {
    width: 100%;
    font-size: 29px;
    opacity: 1;
    letter-spacing: 0.25px;
    position: absolute;
    top: 110px;
    text-transform: uppercase;
    color: #00a8ff;
    font-weight: 200;
    text-align: center;
  }
  
  #home #icon img {
    width: 55px;
    
    opacity: 1;
    letter-spacing: 0.25px;
    position: absolute;
    top: 75px;
    left: 162px;
    text-transform: uppercase;
    color: #00a8ff;
    font-weight: bold;
    text-align: center;
  }
  
  #main.pressed, #main:active {
    -webkit-transform: scale(0.9875);
            transform: scale(0.9875);
    opacity: 0.7;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  

.cookie-policy-container {
  font-family: "Source Code Pro", monospace;
  font-size: 0.5rem;
}